import React from "react"
import Banner from "../../components/Hyderabad/Banner"
import Layout from "../../components/layout"
import Description from "../../components/Hyderabad/Description"
import ButtonLinks from "../../components/Hyderabad/ButtonLinks"
import Faqs from "../../components/Hyderabad/Faqs"
import OurTrustedPartners from "../../components/about/OurTrustedPartners"
import bgImage from "../../../static/assets/hyderabad/erp-solutions.svg"
import data from "../../content/hyderabad/Description/ERPDevelopment.json"
import faqs from "../../content/hyderabad/Faqs/erp.json"
import SEO from "../../components/seo"

const ECommerceDevelopment = () => {
  const keywords = [
    "ERP solutions  services in hyderabad",
    "best ERP solutions  services  company",
    "ERP solutions  services ",
  ]
  return (
    <Layout className="page">
      <SEO
        title="Best ERP Solution Services in Hyderabad | Cresolinfoserv"
        description="Offers a comprehensive ERP solution with modules for serialization, batch and expiry date management, inspection management, and real-time stock status"
        keywords={keywords}
      />
      <Banner
        heading="Best Erp solutions  services In Hyderabad"
        bgImage={bgImage}
      />
      <Description data={data} />
      <ButtonLinks />
      <Faqs faqs={faqs} />
      <OurTrustedPartners />
    </Layout>
  )
}

export default ECommerceDevelopment
